import Cookie from 'js-cookie';
import theme from '../components/theme';
import { MyNewportUser, UserState } from '@services/stores/UserStore';
import { ShopwareUuid } from '@services/types/Shopware';

const COOKIE_NAME: string = process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN || 'my_newport_token';

export interface MyNewportConfig {
    userMethods: UserState;
    basePath: string;
    theme: string;
    isLoggedIn: boolean;
}

export interface MyNewportResponse<T> {
    status: 'success' | 'error' | 'not-logged-in';
    data: T;
    response?: Response;
}

export interface WishlistProductId {
    product_id: ShopwareUuid,
    wishlist_id: number
}

interface WishlistProductIdsResponse extends MyNewportResponse<any>{
    ids: Array<WishlistProductId>;
}

interface LoggedOutResponse {
    status: 'not-logged-in';
    data: null;
}

export interface AuthResponse {
    cookie: string;
    expires: string | Date;
    max_age: number;
    token: string;
}

export interface LoginResponse {
    auth: AuthResponse,
    user: MyNewportUser,
}

export interface ErrorResponse {
    message: string;
    status: 'critical' | 'error';
    data?: any;
    ids?: Array<string>;
}

export type WishlistType = 'wedding' | 'standard' | 'christmas';

export interface CreateWishlist {
    name: string;
    type: WishlistType;
    text: string;
    location: string;
    date: string;
}

class MyNewportService {
    theme: string;
    isLoggedIn: boolean;
    userMethods: UserState;
    basePath: string;

    constructor(MyNewportConfig: MyNewportConfig) {
        let themeConfig = theme.name;
        if (themeConfig === 'xmas') {
            themeConfig = 'christmas';
        }

        this.theme = themeConfig;
        this.isLoggedIn = MyNewportConfig.isLoggedIn;
        this.userMethods = MyNewportConfig?.userMethods;

        this.getCsrfToken();

        if (MyNewportConfig?.basePath?.endsWith('/')) {
            this.basePath = MyNewportConfig.basePath.slice(0, 1);
        } else {
            this.basePath = MyNewportConfig?.basePath;
        }
    }

    getToken() {
        if (typeof Cookie.get(COOKIE_NAME) === 'string') {
            return atob(<string>Cookie.get(COOKIE_NAME));
        } else {
            return false;
        }
    }

    getHeaders() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.getToken()}`,
        };
    }

    getApptusSession() {
        return localStorage.getItem('apptus.session') ? JSON.parse(<string>localStorage.getItem('apptus.session')) : null;
    }

    getLocalDiscoveryKey() {
        return localStorage.getItem('apptus.session') ? JSON.parse(<string>localStorage.getItem('apptus.session')).customerKey : false;
    }

    setLocalDiscoveryKey(discoveryKey: string) {
        const apptus = this.getApptusSession();

        if (apptus) {
            apptus.customerKey = discoveryKey;
            localStorage.setItem('apptus.session', JSON.stringify(apptus));
        }
    }

    apiPath(path: string) {
        return `${this.basePath}/api/${path}`;
    }

    logout() {
        return this.post('auth/logout');
    }

    createLink(path: string) {
        const cleaned = path.split('/').filter((item) => item.length).join('/');
        return `${this.basePath}/${cleaned}`;
    }

    async handleResponse(response: Response | LoggedOutResponse): Promise<MyNewportResponse<any> | MyNewportResponse<ErrorResponse>> {
        if (response.status === 'not-logged-in') {
            return response;
        }

        if (
            response.status === 401
            || response.status === 403
        ) {
            this.userMethods.logout().then();
            return { status: 'not-logged-in', data: null };
        }

        if (response.status >= 400) {
            return {
                status: 'error',
                data: null,
                response
            };
        }

        return await response.json();
    }

    async getCsrfToken() {
        return this.get('/sanctum/csrf-cookie');
    }

    async startBankIdSession() {
        return this.get('auth/bankid/authenticate', true);
    }

    async login(email: string, password: string): Promise<MyNewportResponse<LoginResponse>> {
        return this.post('auth/login', {
            email,
            password,
        }, true);
    }

    async registerNewAccount(registration: {
        first_name: string,
        last_name: string,
        country_code: string,
        website: string,
        email: string,
        password: string,
        password_confirmation: string,
    }) {
        return this.post('auth/register', registration, true);
    }

    async fetchBankIdSessionStatus(orderReferenceId: string) {
        return this.get(`auth/bankid/status/${orderReferenceId}`, true);
    }

    async get(path: string, byPass = false): Promise<MyNewportResponse<any>> {
        if (!this.isLoggedIn && !byPass) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'GET',
            credentials: 'include',
            headers: this.getHeaders(),
        });

        return this.handleResponse(response);
    }

    async delete(path: string): Promise<MyNewportResponse<any>> {
        if (!this.isLoggedIn) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'DELETE',
            credentials: 'include',
            headers: this.getHeaders(),
        });

        return this.handleResponse(response);
    }

    async post(path: string, data?: any | undefined, byPass: boolean = false): Promise<MyNewportResponse<any>> {
        if (!this.isLoggedIn && !byPass) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'POST',
            credentials: 'include',
            headers: this.getHeaders(),
            body: JSON.stringify(data),
        });

        return this.handleResponse(response);
    }

    async getWishlists() {
        try {
            let responseJson = await this.get('wishlist');

            if (responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getWeddingList() {
        try {
            let responseJson = await this.get('wishlist/wedding-list');

            if (!responseJson.data) {
                return { status: 'error', data: {} };
            }

            return { status: 'success', data: responseJson.data };
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getWishlistProducts(wishlistId: string) {
        try {
            let responseJson = await this.get(`wishlist/${wishlistId}`);

            if (responseJson.status === 'error') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getWishlistProductIds(): Promise<Array<WishlistProductId> | ErrorResponse> {
        try {
            let responseJson = <WishlistProductIdsResponse>await this.get('wishlist/product-ids');

            if (responseJson.status === 'error') {
                return [];
            }

            return responseJson?.ids || [];
        } catch (e) {
            return this.handleError(e);
        }
    }

    async addWishlistProduct(wishlistId: string, productId: ShopwareUuid) {
        try {
            let responseJson = await this.post(`wishlist/${wishlistId}/item`, {
                product_id: productId,
                qty: 1,
            });

            if (responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async removeWishlistProduct(wishlistId: string, productId: ShopwareUuid) {
        try {
            let responseJson = await this.delete(`wishlist/${wishlistId}/item/${productId}`);

            if (responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async createWishlist({ name, type, text, location, date }: CreateWishlist) {
        const payload = {
            name,
            type,
            ...(text && { text }),
            ...(location && { location }),
            ...(date && { date }),
        };

        try {
            let responseJson = await this.post('wishlist', payload);

            if (responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getAccountUserData(): Promise<MyNewportResponse<MyNewportUser>> {
        return this.get('auth/account');
    }

    async updateDiscoveryKey(discoveryKey: string) {
        try {
            let response = await this.post('account/discovery-key', {
                discovery_key: discoveryKey,
            });

            if (response.status === 'error') {
                console.error(`Couldn't update discovery key. [${response.status}]`);
            }
        } catch (e) {
            console.error('Couldn\'t update discovery key');
        }
    }

    handleError(e: Error | any): ErrorResponse {
        if (e) {
            console.error('[Wishlist]', e);
        } else {
            throw new Error('UnknownWishlistError');
        }

        return {
            status: 'critical',
            data: null,
            message: 'Failed fetching data',
        };
    }
}

export default MyNewportService;
